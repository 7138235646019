import "./App.css";

function App() {
  const host = window.location.host.split(".");
  const subdomain = host[0] === "www" ? host[1] : host[0];

  return <div className="App">{subdomain}</div>;
}

export default App;
